import React, { useContext, useState } from "react";
import "./PolicyStageFour.css";
import { ISavedIDArrayType } from "../../MarinePolicy";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import {
  ISingleUser,
  UserContext,
} from "../../../../components/contexts/UserContext";
import {
  IAllCargosInputStates,
  IDropdownOption,
  SetErrorHandlerType,
} from "../../../../Types";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../hooks";
import { saveACertificate } from "../../../../redux/actions/customerPoliciesActions";
import CargoSummaryWrapper from "./comps/cargo-summary-wrapper/CargoSummaryWrapper";
import { allBanks } from "../../../../utils/allBanksinNigeria";
import CargoOwnerForm from "./comps/cargo-owner-form/CargoOwnerForm";

// Interfaces
interface IProps {
  companies: [] | ISingleUser[];
  companyName: string;
  pageContent: string;
  brokerCompanyId: string;
  subsidiaryName: string;
  allCargosInputStates: IAllCargosInputStates[];
  policyType: string;
  isUploadedFromSpreadsheet: boolean;
  allCompaniesNames: IDropdownOption[] | null;
  allSubsidiariesNames: IDropdownOption[] | null;
  selectedCompanyFromBroker: ISingleUser | null;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setSubsidiaryName: React.Dispatch<React.SetStateAction<string>>;
  setBrokerCompanyId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCompanyFromBroker: React.Dispatch<
    React.SetStateAction<ISingleUser | null>
  >;
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  setCurrentActiveCargoIndex: React.Dispatch<React.SetStateAction<number>>;
  setSavedCertificateId: React.Dispatch<
    React.SetStateAction<ISavedIDArrayType>
  >;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function PolicyStageFour({
  companies,
  companyName,
  pageContent,
  brokerCompanyId,
  subsidiaryName,
  allCargosInputStates,
  policyType,
  isUploadedFromSpreadsheet,
  allCompaniesNames,
  allSubsidiariesNames,
  selectedCompanyFromBroker,
  setCompanyName,
  setSubsidiaryName,
  setBrokerCompanyId,
  setSelectedCompanyFromBroker,
  setAllCargosInputStates,
  setCurrentActiveCargoIndex,
  setSavedCertificateId,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  const dispatch = useAppDispatch();
  // States
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  // Get total Premium price from selected plan for all cargos
  const getTotalPremiumPlanPrice = function () {
    const totalPremiumPlanPrice = allCargosInputStates?.reduce(
      (acc, obj, index) => {
        return {
          premium: acc.premium + obj.premium,
        };
      },
      { premium: 0 }
    );
    return totalPremiumPlanPrice.premium;
  };

  // Get Bank Name from Bank code
  const getBankNameFromCode = function (bankCode: string) {
    const currentbank = allBanks?.filter((bank) => {
      return bank.key === bankCode;
    });
    if (currentbank) {
      return currentbank[0].value;
    } else {
      return "";
    }
  };

  // Get total insured price for all cargos
  const getTotalSumInsuredPrice = function () {
    const totalSumInsuredPrice = allCargosInputStates?.reduce(
      (acc, obj) => {
        return {
          sumInsured: acc.sumInsured + Number(obj.sumInsured),
        };
      },
      { sumInsured: 0 }
    );
    return totalSumInsuredPrice.sumInsured;
  };

  // Handle Save Certificate and Navigate to Payment
  const handleNavigateToPayment = function () {
    // set error to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    const allCargos = allCargosInputStates?.map((cargoInputs) => {
      const isCurrentBankNameACode = cargoInputs.bankName.includes("code");
      return {
        clause: cargoInputs.clause,
        exchangeRate: Number(cargoInputs.exchangeRates),
        currency: cargoInputs.currency,
        valueOfGoods:
          Number(cargoInputs.amount) * Number(cargoInputs.exchangeRates),
        premium: cargoInputs.premium,
        bankName:
          cargoInputs.bankName === "0"
            ? "NIL"
            : !isCurrentBankNameACode
            ? cargoInputs.bankName
            : getBankNameFromCode(cargoInputs.bankName),
        cargoDescription: cargoInputs.cargoDescription,
        loadingPort: cargoInputs.loadingPort,
        destinationPort: cargoInputs.destinationPort,
        loadingPortID: parseInt(cargoInputs.loadingPortID),
        destinationPortID: parseInt(cargoInputs.destinationPortID),
        invoiceNumber: cargoInputs.invoiceNumber,
        lcNumber: cargoInputs.invoiceNumber,
        sumInsured: Number(cargoInputs.sumInsured),
        conveyance: cargoInputs.conveyance,
        conveyanceID: parseInt(cargoInputs.conveyanceID),
        rate: parseFloat(cargoInputs.rate),
      };
    });

    let data;
    if (user?.customerType === "Broker") {
      // For a brokers certificate for a company
      //  // For Single Transit Certificates (Adding the Total Sum Insured to the Payload)
      policyType === "SingleTransit"
        ? (data = {
            policyType,
            customerId: brokerCompanyId,
            brokerId: user.id,
            subsidiaryId: "",
            cargos: allCargos,
            sumInsured: getTotalSumInsuredPrice(),
          })
        : (data = {
            policyType,
            customerId: brokerCompanyId,
            brokerId: user.id,
            subsidiaryId: "",
            cargos: allCargos,
          });
    } else {
      // For a direct customers certificate
      data = {
        policyType,
        customerId: user.id,
        brokerId: user?.refId ? user.refId : "",
        subsidiaryId: subsidiaryName === "parent" ? "" : subsidiaryName,
        cargos: allCargos,
      };
    }

    // Check if balance is sufficient to register certificate
    const certificateCount = allCargosInputStates.length;
    // Is cargo Owner Name Empty (company for brokers and subsidiary for company)
    const isCargoOwnerNameEmpty =
      user?.customerType === "Broker"
        ? companyName === ""
        : subsidiaryName === "";
    // console.log(data, "-----data");
    // For BROKERS, WE CHECK THE BALANCE OF THE CURRENT COMPANY SELECTED BY THE BROKER ONLY ON OPEN COVER POLICIES
    if (policyType === "OpenCover") {
      if (user?.customerType === "Broker") {
        if (isUploadedFromSpreadsheet && isCargoOwnerNameEmpty) {
          // Check if company name is empty and throw error
          setErrorHandlerObj({
            hasError: true,
            message: "Kindly select a company below for the certificate!",
          });
        } else {
          // IF CUSTOMER TYPE IS A BROKER, VALIDATE SELECTED COMPANY'S BALANCE(Make sure selected company's balance is not empty or less than premium selected)
          (selectedCompanyFromBroker?.paymentAccess.isUnitEnabled &&
            selectedCompanyFromBroker.unit < certificateCount) ||
          (selectedCompanyFromBroker?.paymentAccess.isWalletEnabled &&
            selectedCompanyFromBroker.walletBalance <
              getTotalPremiumPlanPrice())
            ? setErrorHandlerObj({
                hasError: true,
                message:
                  "You don't have enough balance to generate this certificate(s)!",
              })
            : dispatch(
                saveACertificate(
                  data,
                  setErrorHandlerObj,
                  setPageContent,
                  setIsLoading,
                  setSavedCertificateId
                )
              );
        }
      } else {
        // IF USER IS A CUSTOMER, JUST DIRECTLY VALIDATE USER'S BALANCE IN USER'S OBJECT
        if (isUploadedFromSpreadsheet && isCargoOwnerNameEmpty) {
          // Check if subsidiary name is empty and throw error
          setErrorHandlerObj({
            hasError: true,
            message: "Kindly select a subsidiary below for the certificate!",
          });
        } else {
          if (
            user?.paymentAccess.isUnitEnabled &&
            user.unit >= certificateCount
          ) {
            dispatch(
              saveACertificate(
                data,
                setErrorHandlerObj,
                setPageContent,
                setIsLoading,
                setSavedCertificateId
              )
            );
          } else if (
            user?.paymentAccess.isWalletEnabled &&
            user.walletBalance >= getTotalPremiumPlanPrice()
          ) {
            dispatch(
              saveACertificate(
                data,
                setErrorHandlerObj,
                setPageContent,
                setIsLoading,
                setSavedCertificateId
              )
            );
          } else {
            setErrorHandlerObj({
              hasError: true,
              message:
                "You don't have enough balance to generate this certificate(s)!",
            });
          }
          // console.log(user.unit);
          // console.log(user.paymentAccess.isUnitEnabled);
          // console.log(certificateCount);
          // console.log((user?.paymentAccess.isUnitEnabled && user.unit < certificateCount) );
          // (user?.paymentAccess.isUnitEnabled && user.unit < certificateCount) ||
          // (user?.paymentAccess.isWalletEnabled &&
          //   user.walletBalance < getTotalPremiumPlanPrice())
          //   ? setErrorHandlerObj({
          //       hasError: true,
          //       message:
          //         "You don't have enough balance to generate this certificate(s)!",
          //     })
          //   : dispatch(
          //       saveACertificate(
          //         data,
          //         setErrorHandlerObj,
          //         setPageContent,
          //         setIsLoading,
          //         setSavedCertificateId
          //       )
          //     );
        }
      }
    } else {
      // IF POLICY TYPE IS SINGLE TRANSIT, DON'T MAKE ANY VALIDATION CHECK, JUST PROCEED
      if (isUploadedFromSpreadsheet && isCargoOwnerNameEmpty) {
        // Check if company name is empty and throw error (single-transit is for only brokers, therefore, company name check)
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly select a company below for the certificate!",
        });
      } else {
        // VALIDATE SELECTED COMPANY'S BALANCE(Make sure selected company's balance is not empty or less than premium selected)
        //  This check at this points checks if the only available option for single transit payment is credit note
        // Note that there is another check in stage 6 when selecting the payment types
        !user?.paymentAccess?.isRealTimePaymentEnabled &&
        user &&
        user.unit < certificateCount
          ? setErrorHandlerObj({
              hasError: true,
              message:
                "You don't have enough balance to generate this certificate(s)!",
            })
          : dispatch(
              saveACertificate(
                data,
                setErrorHandlerObj,
                setPageContent,
                setIsLoading,
                setSavedCertificateId
              )
            );
      }
    }
  };

  // Handle Add New Cargo
  const handleAddNewCargo = function () {
    const updatedAllCargosInputStates = [
      ...allCargosInputStates,
      {
        exchangeRates: "",
        currency: "USD",
        selectedPremiumPlan: "0",
        selectedClauseRate: 0,
        premium: 0,
        amount: "0",
        sumInsured: "0",
        valueOfGoods: "",
        loadingPort: "",
        destinationPort: "",
        loadingPortID: "",
        destinationPortID: "",
        cargoDescription: "",
        invoiceNumber: "",
        bankName: "0",
        clause: "",
        conveyance: "",
        conveyanceID: "",
        rate: "",
      },
    ];

    setAllCargosInputStates(updatedAllCargosInputStates);
    setPageContent("stage-one");
    setCurrentActiveCargoIndex(updatedAllCargosInputStates.length - 1);
  };

  return (
    // Policy and Cargo Summary Page
    <div className="policy-stage-container policy-stage-four-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        <div className="form-page-header-left-wrapper">
          {/* Onboarding Back Button Wrapper */}
          <div className="onboarding--back-button-wrapper">
            <button onClick={() => setPageContent("stage-three")}>
              <img src={arrowLeftIcon} alt="" />
            </button>
          </div>

          {/* Add another cargo button wrapper */}
          <div className="add-another-cargo-button-wrapper">
            <button onClick={() => handleAddNewCargo()}>
              Add another cargo
            </button>
          </div>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">5/7</div>
      </div>

      <div className="marine-policy-process-summary-container">
        <div className="payment-method-title-wrapper">Details Summary</div>

        {/* Cargo Owner form Wrapper (Subsidiary name || Company Name) */}
        {isUploadedFromSpreadsheet && (
          <CargoOwnerForm
            companies={companies}
            policyType={policyType}
            companyName={companyName}
            subsidiaryName={subsidiaryName}
            allCargosInputStates={allCargosInputStates}
            allCompaniesNames={allCompaniesNames}
            allSubsidiariesNames={allSubsidiariesNames}
            setCompanyName={setCompanyName}
            setSubsidiaryName={setSubsidiaryName}
            setBrokerCompanyId={setBrokerCompanyId}
            setSelectedCompanyFromBroker={setSelectedCompanyFromBroker}
            setAllCargosInputStates={setAllCargosInputStates}
            selectedCompanyFromBroker={selectedCompanyFromBroker}
          />
        )}

        {/* Header Wrapper */}
        <div className="marine-policy-process-summary--header-wrapper">
          {/* Total Premium Plan */}
          <div className="process-premium-plan--summary-wrapper">
            <div className="process-premium-plan--summary-wrapper--inner">
              <div className="process-summary-title">TOTAL PREMIUM PLAN</div>

              <div className="total-premium-plan-value">
                ₦{getTotalPremiumPlanPrice()?.toLocaleString()}
              </div>
            </div>
          </div>

          {/* Submit Marine Policy */}
          <div className="top--submit-marine-policy-button-wrapper">
            <button onClick={() => handleNavigateToPayment()}>
              Proceed to pay
            </button>

            {isLoading && <LoadingSpinner />}
          </div>
        </div>

        {/* Policy Form Edit Container */}
        <div className="policy-form-edit-container">
          {/* Company Name Prompt Container */}
          {!isUploadedFromSpreadsheet && user?.customerType === "Broker" && (
            <div className="company-name-propmt-container">
              *The company with the policy below is {companyName}
            </div>
          )}

          {/* Policy Custom or Default or Normal Summary Wrapper */}
          <div className="policy-custom-summary-container">
            <div className="summary-section-title-wrapper">Policy summary</div>

            {/* Form Value Edit Box  || Sum Insured*/}
            <div className="policy-form-value-edit-wrapper">
              <div className="policy-form-edit--title-wrapper">
                Total Sum Insured:
              </div>
              {/* total sum insured value */}
              <div className="policy-form-edit--value-wrapper">
                <div className="policy-form-edit--value">
                  &#8358;
                  {getTotalSumInsuredPrice().toLocaleString()}
                </div>
              </div>
            </div>
          </div>

          {/*  Certificates or Cargos Summary Wrapper */}
          <CargoSummaryWrapper
            pageContent={pageContent}
            allCargosInputStates={allCargosInputStates}
            isUploadedFromSpreadsheet={isUploadedFromSpreadsheet}
            setAllCargosInputStates={setAllCargosInputStates}
            setCurrentActiveCargoIndex={setCurrentActiveCargoIndex}
            setPageContent={setPageContent}
          />

          {/* Submit Marine Policy */}
          <div className="submit-marine-policy-button-wrapper">
            <button onClick={() => handleNavigateToPayment()}>
              Proceed to pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyStageFour;
