export const allBanks = [
  {
    value: "",
    key: "",
    default: "default",
  },

  {
    key: "9mobile 9Payment Service Bank",
    value: "9mobile 9Payment Service Bank",
  },
  {
    key: "Abbey Mortgage Bank",
    value: "Abbey Mortgage Bank",
  },
  {
    key: "Above Only MFB",
    value: "Above Only MFB",
  },
  {
    key: "Abulesoro MFB",
    value: "Abulesoro MFB",
  },
  {
    key: "Access Bank",
    value: "Access Bank",
  },
  {
    key: "Access Bank (Diamond)",
    value: "Access Bank (Diamond)",
  },
  {
    key: "Accion Microfinance Bank",
    value: "Accion Microfinance Bank",
  },
  {
    key: "Ahmadu Bello University Microfinance Bank",
    value: "Ahmadu Bello University Microfinance Bank",
  },
  {
    key: "Airtel Smartcash PSB",
    value: "Airtel Smartcash PSB",
  },
  {
    key: "AKU Microfinance Bank",
    value: "AKU Microfinance Bank",
  },
  {
    key: "ALAT by WEMA",
    value: "ALAT by WEMA",
  },
  {
    key: "Amegy Microfinance Bank",
    value: "Amegy Microfinance Bank",
  },
  {
    key: "Amju Unique MFB",
    value: "Amju Unique MFB",
  },
  {
    key: "AMPERSAND MICROFINANCE BANK",
    value: "AMPERSAND MICROFINANCE BANK",
  },
  {
    key: "Aramoko MFB",
    value: "Aramoko MFB",
  },
  {
    key: "ASO Savings and Loans",
    value: "ASO Savings and Loans",
  },
  {
    key: "Astrapolaris MFB LTD",
    value: "Astrapolaris MFB LTD",
  },
  {
    key: "Bainescredit MFB",
    value: "Bainescredit MFB",
  },
  {
    key: "Banc Corp Microfinance Bank",
    value: "Banc Corp Microfinance Bank",
  },
  {
    key: "Bowen Microfinance Bank",
    value: "Bowen Microfinance Bank",
  },
  {
    key: "Branch International Financial Services Limited",
    value: "Branch International Financial Services Limited",
  },
  {
    key: "Carbon",
    value: "Carbon",
  },
  {
    key: "CASHCONNECT MFB",
    value: "CASHCONNECT MFB",
  },
  {
    key: "CEMCS Microfinance Bank",
    value: "CEMCS Microfinance Bank",
  },
  {
    key: "Chanelle Microfinance Bank Limited",
    value: "Chanelle Microfinance Bank Limited",
  },
  {
    key: "Chikum Microfinance bank",
    value: "Chikum Microfinance bank",
  },
  {
    key: "Citibank Nigeria",
    value: "Citibank Nigeria",
  },
  {
    key: "Consumer Microfinance Bank",
    value: "Consumer Microfinance Bank",
  },
  {
    key: "Corestep MFB",
    value: "Corestep MFB",
  },
  {
    key: "Coronation Merchant Bank",
    value: "Coronation Merchant Bank",
  },
  {
    key: "County Finance Limited",
    value: "County Finance Limited",
  },
  {
    key: "Crescent MFB",
    value: "Crescent MFB",
  },
  {
    key: "Dot Microfinance Bank",
    value: "Dot Microfinance Bank",
  },
  {
    key: "Ecobank Nigeria",
    value: "Ecobank Nigeria",
  },
  {
    key: "Ekimogun MFB",
    value: "Ekimogun MFB",
  },
  {
    key: "Ekondo Microfinance Bank",
    value: "Ekondo Microfinance Bank",
  },
  {
    key: "Eyowo",
    value: "Eyowo",
  },
  {
    key: "Fairmoney Microfinance Bank",
    value: "Fairmoney Microfinance Bank",
  },
  { key: "FBNQuest Merchant bank", 
    value: "FBNQuest Merchant bank" },
  {
    key: "Fidelity Bank",
    value: "Fidelity Bank",
  },
  {
    key: "Firmus MFB",
    value: "Firmus MFB",
  },
  {
    key: "First Bank of Nigeria",
    value: "First Bank of Nigeria",
  },
  {
    key: "First City Monument Bank",
    value: "First City Monument Bank",
  },
  {
    key: "FirstTrust Mortgage Bank Nigeria",
    value: "FirstTrust Mortgage Bank Nigeria",
  },
  {
    key: "FLOURISH MFB",
    value: "FLOURISH MFB",
  },
  {
    key: "FSDH Merchant Bank Limited",
    value: "FSDH Merchant Bank Limited",
  },
  {
    key: "Gateway Mortgage Bank LTD",
    value: "Gateway Mortgage Bank LTD",
  },
  {
    key: "Gateway Mortgage Bank LTD",
    value: "Gateway Mortgage Bank LTD",
  },
  {
    key: "Globus Bank",
    value: "Globus Bank",
  },
  {
    key: "GoMoney",
    value: "GoMoney",
  },
  {
    key: "Goodnews Microfinance Bank",
    value: "Goodnews Microfinance Bank",
  },
  {
    key: "Greenwich Merchant Bank",
    value: "Greenwich Merchant Bank",
  },
  {
    key: "Guaranty Trust Bank",
    value: "Guaranty Trust Bank",
  },
  {
    key: "Hackman Microfinance Bank",
    value: "Hackman Microfinance Bank",
  },
  {
    key: "Hasal Microfinance Bank",
    value: "Hasal Microfinance Bank",
  },
  {
    key: "Heritage Bank",
    value: "Heritage Bank",
  },
  {
    key: "HopePSB",
    value: "HopePSB",
  },
  {
    key: "Ibile Microfinance Bank",
    value: "Ibile Microfinance Bank",
  },
  {
    key: "Ikoyi Osun MFB",
    value: "Ikoyi Osun MFB",
  },
  {
    key: "Ilaro Poly Microfinance Bank",
    value: "Ilaro Poly Microfinance Bank",
  },
  {
    key: "Imowo MFB",
    value: "Imowo MFB",
  },
  {
    key: "Infinity MFB",
    value: "Infinity MFB",
  },
  {
    key: "Jaiz Bank",
    value: "Jaiz Bank",
  },
  {
    key: "Kadpoly MFB",
    value: "Kadpoly MFB",
  },
  {
    key: "Keystone Bank",
    value: "Keystone Bank",
  },
  {
    key: "Kredi Money MFB LTD",
    value: "Kredi Money MFB LTD",
  },
  {
    key: "Kuda Bank",
    value: "Kuda Bank",
  },
  {
    key: "Lagos Building Investment Company Plc.",
    value: "Lagos Building Investment Company Plc.",
  },
  {
    key: "Links MFB",
    value: "Links MFB",
  },
  {
    key: "Living Trust Mortgage Bank",
    value: "Living Trust Mortgage Bank",
  },
  {
    key: "Lotus Bank",
    value: "Lotus Bank",
  },
  {
    key: "Mayfair MFB",
    value: "Mayfair MFB",
  },
  {
    key: "Mint MFB",
    value: "Mint MFB",
  },
  {
    key: "Moniepoint MFB",
    value: "Moniepoint MFB",
  },
  {
    key: "MTN Momo PSB",
    value: "MTN Momo PSB",
  },
  {
    key: "OPay Digital Services Limited (OPay)",
    value: "OPay Digital Services Limited (OPay)",
  },
  {
    key: "Optimus Bank Limited",
    value: "Optimus Bank Limited",
  },
  {
    key: "Paga",
    value: "Paga",
  },
  {
    key: "PalmPay",
    value: "PalmPay",
  },
  {
    key: "Parallex Bank",
    value: "Parallex Bank",
  },
  {
    key: "Parkway - ReadyCash",
    value: "Parkway - ReadyCash",
  },
  {
    key: "Peace Microfinance Bank",
    value: "Peace Microfinance Bank",
  },
  {
    key: "Personal Trust MFB",
    value: "Personal Trust MFB",
  },
  {
    key: "Petra Mircofinance Bank Plc",
    value: "Petra Mircofinance Bank Plc",
  },
  {
    key: "Platinum Mortgage Bank",
    value: "Platinum Mortgage Bank",
  },
  {
    key: "Polaris Bank",
    value: "Polaris Bank",
  },
  {
    key: "Polyunwana MFB",
    value: "Polyunwana MFB",
  },
  {
    key: "PremiumTrust Bank",
    value: "PremiumTrust Bank",
  },
  {
    key: "Providus Bank",
    value: "Providus Bank",
  },
  {
    key: "QuickFund MFB",
    value: "QuickFund MFB",
  },
  {
    key: "Rand Merchant Bank",
    value: "Rand Merchant Bank",
  },
  {
    key: "Refuge Mortgage Bank",
    value: "Refuge Mortgage Bank",
  },
  {
    key: "Rephidim Microfinance Bank",
    value: "Rephidim Microfinance Bank",
  },
  {
    key: "Rigo Microfinance Bank Limited",
    value: "Rigo Microfinance Bank Limited",
  },
  {
    key: "ROCKSHIELD MICROFINANCE BANK",
    value: "ROCKSHIELD MICROFINANCE BANK",
  },
  {
    key: "Rubies MFB",
    value: "Rubies MFB",
  },
  {
    key: "Safe Haven MFB",
    value: "Safe Haven MFB",
  },
  {
    key: "Safe Haven Microfinance Bank Limited",
    value: "Safe Haven Microfinance Bank Limited",
  },
  {
    key: "SAGE GREY FINANCE LIMITED",
    value: "SAGE GREY FINANCE LIMITED",
  },
  {
    key: "Shield MFB",
    value: "Shield MFB",
  },
  {
    key: "Solid Allianze MFB",
    value: "Solid Allianze MFB",
  },
  {
    key: "Signature Bank",
    value: "Signature Bank",
  },
  {
    key: "Solid Rock MFB",
    value: "Solid Rock MFB",
  },
  {
    key: "Sparkle Microfinance Bank",
    value: "Sparkle Microfinance Bank",
  },
  {
    key: "Stanbic IBTC Bank",
    value: "Stanbic IBTC Bank",
  },
  {
    key: "Standard Chartered Bank",
    value: "Standard Chartered Bank",
  },
  {
    key: "Stellas MFB",
    value: "Stellas MFB",
  },
  {
    key: "Sterling Bank",
    value: "Sterling Bank",
  },
  {
    key: "Suntrust Bank",
    value: "Suntrust Bank",
  },
  {
    key: "Supreme MFB",
    value: "Supreme MFB",
  },
  {
    key: "TAJ Bank",
    value: "TAJ Bank",
  },
  {
    key: "Tanadi Microfinance Bank",
    value: "Tanadi Microfinance Bank",
  },
  {
    key: "Tangerine Money",
    value: "Tangerine Money",
  },
  {
    key: "TCF MFB",
    value: "TCF MFB",
  },
  {
    key: "Titan Bank",
    value: "Titan Bank",
  },
  {
    key: "Titan Paystack",
    value: "Titan Paystack",
  },
  {
    key: "U&C Microfinance Bank Ltd (U AND C MFB)",
    value: "U&C Microfinance Bank Ltd (U AND C MFB)",
  },
  {
    key: "Uhuru MFB",
    value: "Uhuru MFB",
  },
  {
    key: "Unaab Microfinance Bank Limited",
    value: "Unaab Microfinance Bank Limited",
  },
  {
    key: "Unical MFB",
    value: "Unical MFB",
  },
  {
    key: "Unilag Microfinance Bank",
    value: "Unilag Microfinance Bank",
  },
  {
    key: "Union Bank of Nigeria",
    value: "Union Bank of Nigeria",
  },
  {
    key: "United Bank For Africa",
    value: "United Bank For Africa",
  },
  {
    key: "Unity Bank",
    value: "Unity Bank",
  },
  {
    key: "VFD Microfinance Bank Limited",
    value: "VFD Microfinance Bank Limited",
  },
  {
    key: "Waya Microfinance Bank",
    value: "Waya Microfinance Bank",
  },
  {
    key: "Wema Bank",
    value: "Wema Bank",
  },
  {
    key: "Zenith Bank",
    value: "Zenith Bank",
  },
];
