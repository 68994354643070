import React, { useContext, useEffect } from "react";
import {
  IAllCargosInputStates,
  ICargoXlsxJson,
  IDropdownOption,
} from "../../../../Types";
import { UserContext } from "../../../../components/contexts/UserContext";
import isEmpty from "../../../../validation/isEmpty";
import { allClauseTypes } from "../../../../utils/allClauseTypes";
import { ISinglePolicyPlan } from "../policy-stage-three/PolicyStageThree";
import { getSumInsured } from "../../../../utils/getSumInsured";
import { getInsurancePremium } from "../../../../utils/generatePremium";

// Interfaces
interface IProps {
  cargoJSONArr: [] | ICargoXlsxJson[];
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
}

function CargosJsonProcess({
  cargoJSONArr,
  setAllCargosInputStates,
  setPageContent,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);

  //   Handle get selected clause plan from clause name;
  const handleGetSelectedPlanForCurrentClauseName = function (
    clauseName: string
  ) {
    const allUsersClauses = allClauseTypes?.map(
      (clauseType: IDropdownOption) => {
        return {
          value: clauseType.value,
          key: clauseType.key,
          rate: user?.rate[clauseType.key] || 0,
        };
      }
    );
    const selectedPlan = allUsersClauses.filter(
      (clause: ISinglePolicyPlan) => clause.key === clauseName
    )[0];
    return selectedPlan || [];
  };

  //   Handle get premium price on cargo
  // const handleGetPremiumAmountOnCargo = function (
  //   currentCargoSumInsuredAmount: number,
  //   cargoClauseRate: number
  // ) {
  //   // Multiplying the sum insured with the clause rate percentage
  //   const premiumAmount =
  //     currentCargoSumInsuredAmount * (cargoClauseRate / 100);
  //   return premiumAmount.toFixed();
  // };

  //   Handle get sum insured amount on cargo
  const handleGetSumInsuredAmountOnCargo = function (
    cargoValue: number,
    cargoExchangeRate: number,
    cargoCurrency: string
  ) {
    const sumInsuredAmount = getSumInsured(
      cargoValue,
      cargoExchangeRate,
      cargoCurrency === "NGN"
    );
    return sumInsuredAmount;
  };

  //   Useeffects
  useEffect(() => {
    if (!isEmpty(user)) {
      // Update the all cargo input states on component mount
      setAllCargosInputStates(() => {
        // Get Update all cargo inputs in case exchange rate changes. (sum insured is dependent on exchange rate)
        const newCargoStateInputs = cargoJSONArr?.map((cargoObj) => {
          // Sum insured
          const currentCargoSumInsuredAmount = handleGetSumInsuredAmountOnCargo(
            cargoObj.valueOfGoods,
            cargoObj.exchangeRate,
            cargoObj.currency
          );
          //   Clause Rate
          const currentCargoClauseRate =
            handleGetSelectedPlanForCurrentClauseName(cargoObj.clause).rate;
          //   Premium Amount
          const currentCargoPremiumAmount = getInsurancePremium(
            currentCargoSumInsuredAmount,
            currentCargoClauseRate
          );
          return {
            exchangeRates: cargoObj.exchangeRate.toString(),
            currency: cargoObj.currency,
            selectedPremiumPlan: cargoObj.clause,
            selectedClauseRate: currentCargoClauseRate,
            premium: Number(currentCargoPremiumAmount.toFixed()),
            amount: cargoObj.valueOfGoods.toString(),
            sumInsured: currentCargoSumInsuredAmount.toString(),
            valueOfGoods: cargoObj.valueOfGoods.toString(),
            loadingPort: cargoObj.loadingPort,
            destinationPort: cargoObj.destinationPort,
            loadingPortID: cargoObj.loadingPortID.toString(),
            destinationPortID: cargoObj.destinationPortID.toString(),
            cargoDescription: cargoObj.cargoDescription,
            invoiceNumber: cargoObj.invoiceNumber,
            bankName: cargoObj?.bankName ? cargoObj.bankName : "0",
            clause: cargoObj.clause,
            conveyance: cargoObj.conveyance,
            conveyanceID: cargoObj.conveyanceID.toString(),
            rate: currentCargoClauseRate,
          };
        });
        // console.log(newCargoStateInputs, "heree---------------heree");
        setPageContent("stage-four");
        // Update the current cargo object
        return newCargoStateInputs;
      });
    }
  }, [cargoJSONArr, user]);

  return <></>;
}

export default CargosJsonProcess;
