export const allConveyances = [
  { key: "60780", value: "BY AIR" },
  { key: "60570", value: "BY AIR/SEA" },
  { key: "60778", value: "BY ROAD" },
  { key: "60779", value: "BY SEA" },
  { key: "60264", value: "EGYPT" },
  { key: "61542", value: "NHAVA SHEVA, INDIA" },
  { key: "60861", value: "RAIL" },
  { key: "60862", value: "RAIL/ROAD" },
  { key: "60863", value: "SEA/LAND" },
  { key: "137608", value: "Uruguay" },
];
