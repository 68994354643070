import React, { useContext, useEffect, useState } from "react";
import "./PolicyStageThree.css";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import { UserContext } from "../../../../components/contexts/UserContext";
import {
  IAllCargosInputStates,
  IDropdownOption,
  SetErrorHandlerType,
} from "../../../../Types";
import isEmpty from "../../../../validation/isEmpty";
import { InfoCircleOutlined } from "@ant-design/icons";
import { allClauseTypes } from "../../../../utils/allClauseTypes";
import { getInsurancePremium } from "../../../../utils/generatePremium";

// Interfaces
interface IProps {
  allCargosInputStates: IAllCargosInputStates[];
  setAllCargosInputStates: React.Dispatch<
    React.SetStateAction<IAllCargosInputStates[]>
  >;
  currentActiveCargoIndex: number;
  setIsSumInsuredBreakdownModalOpened: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  pageContent: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

export interface ISinglePolicyPlan {
  value: string;
  key: string;
  rate: number;
}

function PolicyStageThree({
  allCargosInputStates,
  setAllCargosInputStates,
  currentActiveCargoIndex,
  setIsSumInsuredBreakdownModalOpened,
  pageContent,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  // States
  const [selectedPremiumPlan, setSelectedPremiumPlan] =
    useState<ISinglePolicyPlan | null>(null);

  //   Functions
  // handle Navigate To Page Three Five / Navigate to policy type selection page
  const handleNavigateToPageThreeFive = function (
    selectedPlan: ISinglePolicyPlan | null
  ) {
    // Navigate to next page
    setPageContent("stage-four");
  };

  // UseEffects
  useEffect(() => {
    if (pageContent === "stage-three" && !isEmpty(user)) {
      const plans = allClauseTypes?.map((clauseType: IDropdownOption) => {
        return {
          value: clauseType.value,
          key: clauseType.key,
          rate: user?.rate[clauseType.key] || 0,
        };
      });

      if (plans) {
        const currentSelectedPlan: ISinglePolicyPlan[] = plans?.filter(
          (plan: ISinglePolicyPlan) =>
            plan.key === allCargosInputStates[currentActiveCargoIndex].clause
        );
        setSelectedPremiumPlan(currentSelectedPlan[0]);
      }
    }
  }, [pageContent, user]);

  return (
    <div className="policy-stage-container policy-stage-one-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-two")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">3/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        {/* Form Two Header Box Wrapper */}
        <div className="policy-form-three-header-box-wrapper">
          <div className="form-header-box--sub-text">Sum insured</div>
          <div className="form-header-box--main-text">
            ₦
            {Number(
              allCargosInputStates[currentActiveCargoIndex].sumInsured
            )?.toLocaleString()}
          </div>

          <div className="sum-insured-info-button-wrapper">
            <button
              title="Breakdown of sum insured"
              onClick={() => setIsSumInsuredBreakdownModalOpened(true)}
            >
              <InfoCircleOutlined color="red" />
            </button>
          </div>
        </div>

        {/* Ploicy Plan Selection Container */}
        <div className="policy-plan-selection-container">
          {/* Title Wrapper */}
          <div className="policy-plan-selection-title-wrapper">
            Premium plan
          </div>

          {/* Boxes Wrapper */}
          <div className="policy-plan-selection-boxes-wrapper">
            <div className={`policy-plan-box-wrapper`}>
              <div className="policy-plan-box-wrapper--inner">
                {/* Plan Name */}
                <div className="plan-name-wrapper">
                  {selectedPremiumPlan?.value}
                </div>
                {/* Plan Price */}
                <div className="plan-price-wrapper">
                  ₦
                  {/* {(
                    parseInt(
                      allCargosInputStates[currentActiveCargoIndex]?.sumInsured
                    ) *
                    parseFloat(
                      allCargosInputStates[currentActiveCargoIndex].rate
                    )
                  )
                    // (selectedPremiumPlan ? selectedPremiumPlan?.rate : 0)
                    .toLocaleString()} */}
                  {getInsurancePremium(
                    Number(
                      allCargosInputStates[currentActiveCargoIndex]?.sumInsured
                    ),
                    parseFloat(
                      allCargosInputStates[currentActiveCargoIndex].rate
                    )
                  )?.toLocaleString()}
                </div>

                <div className="buy-plan-button-wrapper">
                  <button
                    onClick={() =>
                      handleNavigateToPageThreeFive(selectedPremiumPlan)
                    }
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyStageThree;
