import React, { ChangeEvent, MouseEvent, useContext, useState } from "react";
import "./PolicyStageSix.css";
import { ISavedIDArrayType } from "../../MarinePolicy";
import arrowLeftIcon from "../../../../images/arrow-left-icon.svg";
import boxEdgeVector from "../../../../images/box-edge-vector.svg";
import uploadIcon from "../../../../images/upload-icon.svg";
import paystackIcon from "../../../../images/paystack-icon.svg";
import {
  IAllCargosInputStates,
  IStageFivePolicyFormStates,
  IStageFourPolicyFormStates,
  ISubmittedCertificateData,
  SetErrorHandlerType,
} from "../../../../Types";
import {
  ISingleUser,
  UserContext,
} from "../../../../components/contexts/UserContext";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { useAppDispatch } from "../../../../hooks";
import { registerACertificate } from "../../../../redux/actions/customerPoliciesActions";
import { paymentMethods } from "../../../../utils/allPaymentMethods";
import { convertToBase64 } from "../../../../utils/convertToBase64";
import { api } from "../../../../api/config";
import axios from "axios";
import { handleGetFileExtension } from "../../../../utils/getFileExtension";

// Interfaces
interface IProps {
  allCargosInputStates: IAllCargosInputStates[];
  stageFourInputStates: IStageFourPolicyFormStates;
  savedCertificateId: ISavedIDArrayType;
  selectedCompanyFromBroker: ISingleUser | null;
  setSubmittedCertificatesData: React.Dispatch<
    React.SetStateAction<ISubmittedCertificateData[] | null>
  >;
  setStageFiveInputStates: React.Dispatch<
    React.SetStateAction<IStageFivePolicyFormStates>
  >;
  policyType: string;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  setErrorHandlerObj: SetErrorHandlerType;
}

function PolicyStageSix({
  allCargosInputStates,
  stageFourInputStates,
  savedCertificateId,
  selectedCompanyFromBroker,
  setSubmittedCertificatesData,
  setStageFiveInputStates,
  policyType,
  setPageContent,
  setErrorHandlerObj,
}: IProps) {
  // Functions, States and Variables
  const { user }: any = useContext(UserContext);
  const dispatch = useAppDispatch();
  // States
  const [creditNoteFile, setCreditNoteFile] = useState("");
  const [creditNoteFileUrl, setCreditNoteFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadCreditNoteLoading, setIsUploadCreditNoteLoading] =
    useState(false);
  //   Functions

  const handleSubmitMarinePolicy = function (e: MouseEvent<HTMLButtonElement>) {
    // set error to default false
    setErrorHandlerObj({ hasError: false, message: "" });

    let data = {
      paymentMethod: stageFourInputStates.paymentMethod,
      creditNoteUrl: creditNoteFileUrl,
      certificateIds: savedCertificateId ? savedCertificateId : [],
    };

    if (user?.customerType === "Broker") {
      // Handle Registration for brokers
      handleRegisterCertificateForBrokers(e, data);
    } else {
      // Handle Registration for customers
      handleRegisterCertificateForCustomers(e, data);
    }

    // console.log(data, "data to be submitted");
  };

  // Handles Certificate Registration Procedure if user type is BROKER
  const handleRegisterCertificateForBrokers = function (
    e: MouseEvent<HTMLButtonElement>,
    data: any
  ) {
    // Clear out if no broker was selected, worst case
    if (!selectedCompanyFromBroker) return false;
    const certificateCount = allCargosInputStates.length;
    if (stageFourInputStates.paymentMethod === "Unit") {
      // If Payment method is UNIT
      // Check if unit balance is more than certificates to be generated
      if (selectedCompanyFromBroker.unit >= certificateCount) {
        //  Call the API to register policy with the saved policy Id
        // console.log(data, "data to be submitted");

        dispatch(
          registerACertificate(
            data,
            setErrorHandlerObj,
            setPageContent,
            setIsLoading,
            setSubmittedCertificatesData
          )
        );
      } else {
        // If Units is NOT sufficient
        setErrorHandlerObj({
          hasError: true,
          message:
            "You don't have enough units to generate this certificate(s)!",
        });
      }
    } else if (stageFourInputStates.paymentMethod === "Wallet") {
      // If Payment method is WALLET
      if (
        selectedCompanyFromBroker?.walletBalance >= getTotalPremiumPlanPrice()
      ) {
        //  Call the API to register policy with the saved policy Id
        dispatch(
          registerACertificate(
            data,
            setErrorHandlerObj,
            setPageContent,
            setIsLoading,
            setSubmittedCertificatesData
          )
        );
      } else {
        // If Wallet balance is NOT sufficient
        setErrorHandlerObj({
          hasError: true,
          message:
            "You don't have enough premium balance to generate this certificate(s)!",
        });
      }
    } else if (stageFourInputStates.paymentMethod === "CreditNote") {
      // If Payment method is CREDITNOTE
      // IF the file has been uploaded
      if (creditNoteFile !== "") {
        e.preventDefault();
        // Check if the user has units more than the certificate count before registering the certificate
        if (user.unit >= certificateCount) {
          // console.log(data, "data to be submitted");
          //  Call the API to register policy with the saved policy Id
          dispatch(
            registerACertificate(
              data,
              setErrorHandlerObj,
              setPageContent,
              setIsLoading,
              setSubmittedCertificatesData
            )
          );
        } else {
          // If Units is NOT sufficient
          setErrorHandlerObj({
            hasError: true,
            message:
              "You don't have enough units to generate this certificate(s)!",
          });
        }
      }
    }
  };

  // Handles Certificate Registration Procedure if user type is CUSTOMER
  const handleRegisterCertificateForCustomers = function (
    e: MouseEvent<HTMLButtonElement>,
    data: any
  ) {
    // If payment method is unit
    if (stageFourInputStates.paymentMethod === "Unit") {
      // If Payment method is UNIT
      // Check if unit balance is more than certificates to be generated
      const certificateCount = allCargosInputStates.length;
      if (user.unit >= certificateCount) {
        //  Call the API to register policy with the saved policy Id
        // console.log(data, "data to be submitted");

        dispatch(
          registerACertificate(
            data,
            setErrorHandlerObj,
            setPageContent,
            setIsLoading,
            setSubmittedCertificatesData
          )
        );
      } else {
        // If Units is NOT sufficient
        setErrorHandlerObj({
          hasError: true,
          message:
            "You don't have enough units to generate this certificate(s)!",
        });
      }
    } else if (stageFourInputStates.paymentMethod === "Wallet") {
      // If Payment method is WALLET
      if (user?.walletBalance >= getTotalPremiumPlanPrice()) {
        //  Call the API to register policy with the saved policy Id
        dispatch(
          registerACertificate(
            data,
            setErrorHandlerObj,
            setPageContent,
            setIsLoading,
            setSubmittedCertificatesData
          )
        );
      } else {
        // If Wallet balance is NOT sufficient
        setErrorHandlerObj({
          hasError: true,
          message:
            "You don't have enough premium balance to generate this certificate(s)!",
        });
      }
    }
    // FIX This was muted because customers cannot have a SingleTransit policy hence no "Pay now" and "credit Note" process
    // else if (stageFourInputStates.paymentMethod === "CreditNote") {
    //   // If Payment method is CREDITNOTE
    //   // IF the file has been uploaded
    //   if (creditNoteFile !== "") {
    //     e.preventDefault();
    //     //  Call the API to register policy with the saved policy Id
    //     dispatch(
    //       registerACertificate(
    //         data,
    //         setErrorHandlerObj,
    //         setPageContent,
    //         setIsLoading,
    //         setSubmittedCertificatesData
    //       )
    //     );
    //   }
    // }
  };

  // Get total Premium price from selected plan for all cargos
  const getTotalPremiumPlanPrice = function () {
    const totalPremiumPlanPrice = allCargosInputStates?.reduce(
      (acc, obj) => {
        return {
          premium: acc.premium + obj.premium,
        };
      },
      { premium: 0 }
    );
    return totalPremiumPlanPrice.premium;
  };

  const handleTriggerCreditNoteInputClick = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    const creditNoteInput = document.getElementById("credit-note-file-input");
    creditNoteInput?.click();
  };

  // handle Get Payment Method Name
  const handleGetPaymentMethodName = function () {
    const selectedPaymentMethod = paymentMethods?.filter(
      (paymentMethod) =>
        paymentMethod.pageValue === stageFourInputStates.paymentMethod
    );
    return selectedPaymentMethod[0]?.name || "";
  };

  // handle Upload Credit Note File // FOR CREDIT NOTE
  const handleUploadCreditNoteFile = function (
    e: ChangeEvent<HTMLInputElement>
  ) {
    // Set Error to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setCreditNoteFile(e.target.value);
      // Call the API to upload credit note and set it to stage five state's data
      // Convert file to Base 64
      convertToBase64(selectedFile)
        .then(async (result) => {
          //   Call the API to upload file and get the link as data
          const fileData = {
            url: result,
            use: "idcard",
          };
          setIsUploadCreditNoteLoading(true);
          //
          const url = `${api}/marineportal/upload`;
          // Call the API to upload file and get data as response
          const { data } = await axios.post(url, fileData);
          // console.log(data.imageUrl, "url");
          setCreditNoteFileUrl(data.imageUrl);
          setIsUploadCreditNoteLoading(false);
        })
        .catch((err) => {
          setIsUploadCreditNoteLoading(false);
          // If Upload fails, set credit note back to default empty to prevent false preventDefault check
          setCreditNoteFile("");
          setErrorHandlerObj({ hasError: true, message: "Upload failed!" });
        });
    }
  };

  return (
    // Payment Stage Two Page
    <div className="policy-stage-container policy-stage-six-container">
      {/* Policy processing form page header wrapper*/}
      <div className="onboarding-form-page-header-wrapper">
        {/* Onboarding Back Button Wrapper */}
        <div className="onboarding--back-button-wrapper">
          <button onClick={() => setPageContent("stage-five")}>
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>

        {/* Page Number Wrapper */}
        <div className="page-number-wrapper">7/7</div>
      </div>

      {/* Policy Processing Form Wrapper */}
      <div className="policy-processing-form-wrapper">
        <div className="payment-method-title-wrapper">
          {handleGetPaymentMethodName()}
        </div>

        {/* Form Four Header Box Wrapper || Doesn't get displayed on premium deposit */}
        {stageFourInputStates.paymentMethod !== "premium-deposit" && (
          <div className="policy-form-three-header-box-wrapper proceed-with-selected-payment-header-box">
            {/* vector wrapper */}
            <div className="box-edge-vector-wrapper">
              <img src={boxEdgeVector} alt="" />
            </div>
            <div className="form-header-box--sub-text">Payment amount</div>
            <div className="form-header-box--main-text">
              {stageFourInputStates.paymentMethod === "use-units"
                ? `${allCargosInputStates.length} units`
                : `₦${getTotalPremiumPlanPrice().toLocaleString()}`}
            </div>

            {/* NOTE: ONLY GETS DISPLAYED ON PAYMENT IN ARREARS */}
            {stageFourInputStates.paymentMethod === "payment-in-arrears" && (
              <div className="proceed-to-pay-in-arrears-button-wrapper">
                <button
                  onClick={(e) => {
                    handleSubmitMarinePolicy(e);
                  }}
                >
                  Proceed to generate certificate
                </button>
              </div>
            )}

            {/* NOTE: ONLY GETS DISPLAYED ON PAYMENT WITH UNITS || WALLET / PREMIUM DEPOSIT THAT ARE OPEN COVER CERTIFICATES */}
            {policyType === "OpenCover" &&
              (stageFourInputStates.paymentMethod === "Unit" ||
                stageFourInputStates.paymentMethod === "Wallet") && (
                <div className="proceed-to-pay-in-arrears-button-wrapper">
                  <button
                    onClick={(e) => {
                      handleSubmitMarinePolicy(e);
                    }}
                  >
                    Proceed to generate certificate
                  </button>
                  {isLoading && <LoadingSpinner />}
                </div>
              )}
          </div>
        )}

        {/* Policy Plan Selection Container */}
        <div className="policy-plan-selection-container">
          {/* NOTE: ONLY GETS DISPLAYED ON PAYMENT WITH UNITS || WALLET / PREMIUM DEPOSIT THAT ARE OPEN COVER CERTIFICATES */}
          {policyType === "SingleTransit" &&
            stageFourInputStates.paymentMethod === "CreditNote" && (
              <form>
                <div className="credit-note-file-upload-container">
                  {/* Upload img wrapper */}
                  <div className="upload-img-wrapper">
                    <img src={uploadIcon} alt="" />
                  </div>

                  {creditNoteFileUrl && (
                    <div className="credit-note-img-wrapper">
                      {creditNoteFileUrl &&
                        handleGetFileExtension(creditNoteFile) !== "pdf" && (
                          <img src={creditNoteFileUrl} alt="" />
                        )}
                    </div>
                  )}

                  <div className="upload-img-title">
                    {/* Drag and drop <span>or upload credit note here</span> */}
                    {creditNoteFileUrl ? (
                      <>
                        <span>Change credit note here</span>
                      </>
                    ) : (
                      <>
                        Drag and drop <span>or upload credit note here</span>
                      </>
                    )}
                  </div>

                  <div>{isUploadCreditNoteLoading && <LoadingSpinner />}</div>

                  <input
                    type="file"
                    accept="image/*,.pdf"
                    id="credit-note-file-input"
                    className="credit-note-file-input"
                    value={creditNoteFile}
                    onChange={(e) => handleUploadCreditNoteFile(e)}
                    required
                  />
                </div>

                {/* Preview and Chane Button Wrapper */}
                {creditNoteFileUrl && (
                  <div className="credit-note-file-preview-btn-wrapper">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(creditNoteFileUrl, "_blank");
                      }}
                    >
                      Preview credit note
                    </button>
                    <button
                      onClick={(e) => {
                        handleTriggerCreditNoteInputClick(e);
                      }}
                    >
                      Change credit note
                    </button>
                  </div>
                )}

                <div className="proceed-to-pay-in-arrears-button-wrapper">
                  <button
                    onClick={(e) => {
                      handleSubmitMarinePolicy(e);
                    }}
                  >
                    Proceed to generate certificate
                  </button>
                  {isLoading && <LoadingSpinner />}
                </div>
              </form>
            )}

          {/* If Payment method is in real time */}
          {stageFourInputStates.paymentMethod === "real-time" && (
            <div className="continue-using-paystack-button-wrapper">
              <button
                onClick={(e) => {
                  handleSubmitMarinePolicy(e);
                }}
              >
                Continue using <img src={paystackIcon} alt="" />
              </button>
            </div>
          )}

          {/* Gets Displayed on premium deposit*/}
          {stageFourInputStates.paymentMethod === "premium-deposit" && (
            <div className="premium-deposit-withdrawal-wrapper">
              <div className="premium-deposit-withdrawal-wrapper--inner">
                {/* Title */}
                <div className="premium-deposit--wallet-balance-title-wrapper">
                  Wallet balance
                </div>
                {/* Balance Value */}
                <div className="premium-deposit--wallet-balance-value-wrapper">
                  ₦210,000
                </div>
                {/* Top up wallet */}
                <div className="top-up-wallet-button-wrapper">
                  <button>Top up wallet</button>
                </div>
              </div>
              <div className="premium-deposit-withdrawal-payment-amount-container">
                <div className="premium-deposit-withdrawal-payment-amount-container--inner">
                  {/* vector wrapper */}
                  <div className="box-edge-vector-wrapper gradient-border--box-edge-vector-wrapper">
                    <img src={boxEdgeVector} alt="" />
                  </div>
                  <div className="form-header-box--sub-text">
                    Payment amount
                  </div>
                  <div className="form-header-box--main-text">
                    ₦{getTotalPremiumPlanPrice()}
                  </div>

                  <div className="approve-payment-deduction-button-wrapper">
                    <button
                      onClick={(e) => {
                        handleSubmitMarinePolicy(e);
                      }}
                    >
                      Approve deduction
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PolicyStageSix;
