import React, { useContext, useEffect, useState } from "react";
import "./MarinePolicy.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import PolicyStageThree from "./marine-policy-process/policy-stage-three/PolicyStageThree";
import PolicyProcessSuccessful from "./marine-policy-process/policy-process-successful/PolicyProcessSuccessful";
import PolicyStageFour from "./marine-policy-process/policy-stage-four/PolicyStageFour";
import PolicyStageFive from "./marine-policy-process/policy-stage-five/PolicyStageFive";
import PolicyStageSix from "./marine-policy-process/policy-stage-six/PolicyStageSix";
import PolicyStageTwo from "./marine-policy-process/policy-stage-two/PolicyStageTwo";
import PolicyStageTwoFive from "./marine-policy-process/policy-stage-two-five/PolicyStageTwoFive";
import ErrorHandler from "../../components/error/ErrorHandler";
import PolicyStageZeroFive from "./marine-policy-process/policy-stage-zero-five/PolicyStageZeroFive";
import SumInsuredBreakdownModal from "./marine-policy-process/policy-stage-four/modal/SumInsuredBreakdownModal";
import {
  ISingleUser,
  UserContext,
} from "../../components/contexts/UserContext";
import UserPaused from "./marine-policy-process/user-paused/UserPaused";
import useDisplayMessage from "../../utils/hooks/useDisplayMessage";
import {
  IAllCargosInputStates,
  ICargoXlsxJson,
  IDropdownOption,
  ISingleSubsidiary,
  IStageFivePolicyFormStates,
  IStageFourPolicyFormStates,
  ISubmittedCertificateData,
} from "../../Types";
import { getAllCompaniesForBroker } from "../../redux/actions/companyActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import PolicyStageOne from "./marine-policy-process/policy-stage-one/PolicyStageOne";
import isEmpty from "../../validation/isEmpty";
import CargosJsonProcess from "./marine-policy-process/policy-xlsx-json-process/CargosJsonProcess";
import { useLocation } from "react-router-dom";

// Interfaces
export type ISavedIDArrayType = string[] | null;

function MarinePolicy() {
  // Functions, states and variables
  const { user }: any = useContext(UserContext);
  const companies: ISingleUser[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  // States
  const [pageContent, setPageContent] = useState("");
  // Submitted Certificates Data
  const [submittedCertificatesData, setSubmittedCertificatesData] = useState<
    ISubmittedCertificateData[] | null
  >(null);
  const [savedCertificateId, setSavedCertificateId] =
    useState<ISavedIDArrayType>(null);
  // Stage One Inputs / All cargo input states
  const [allCargosInputStates, setAllCargosInputStates] = useState<
    IAllCargosInputStates[]
  >([
    {
      exchangeRates: "",
      currency: "USD",
      selectedPremiumPlan: "0",
      selectedClauseRate: 0,
      premium: 0,
      amount: "0",
      sumInsured: "0",
      valueOfGoods: "",
      loadingPort: "",
      destinationPort: "",
      loadingPortID: "",
      destinationPortID: "",
      cargoDescription: "",
      invoiceNumber: "",
      bankName: "0",
      clause: "",
      conveyance: "",
      conveyanceID: "",
      rate: "",
    },
  ]);
  const [policyType, setPolicyType] = useState("OpenCover");
  const [companyName, setCompanyName] = useState("");
  const [selectedCompanyFromBroker, setSelectedCompanyFromBroker] =
    useState<ISingleUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // Subsidiaries
  const [subsidiaryName, setSubsidiaryName] = useState("");

  // Brokers's company id
  const [brokerCompanyId, setBrokerCompanyId] = useState("");

  // Curenct active cargo index
  const [currentActiveCargoIndex, setCurrentActiveCargoIndex] = useState(0);

  // Sum Insured Breakdown wrapper
  const [
    isSumInsuredBreakdownModalOpened,
    setIsSumInsuredBreakdownModalOpened,
  ] = useState(false);

  // Payment Method
  const [stageFourInputStates, setStageFourInputStates] =
    useState<IStageFourPolicyFormStates>({
      paymentMethod: "",
    });

  // Payment data / credit score note
  const [stageFiveInputStates, setStageFiveInputStates] =
    useState<IStageFivePolicyFormStates>({
      creditScoreNote: "",
    });

  // Subsidiary and Companies for brokers options array
  const [allCompaniesNames, setAllCompaniesNames] = useState<
    IDropdownOption[] | null
  >(null);
  const [allSubsidiariesNames, setAllSubsidiariesNames] = useState<
    IDropdownOption[] | null
  >(null);

  // Cargo JSON Array coming form XLSX file parse
  const [cargoJSONArr, setCargoJSONArr] = useState<ICargoXlsxJson[] | []>([]);
  const [isUploadedFromSpreadsheet, setIsUploadedFromSpreadsheet] =
    useState(false);

  // Register certificate only
  const [
    isRoutedToRegisterCertificateOnly,
    setIsRoutedToRegisterCertificateOnly,
  ] = useState(false);

  // Hooks
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();

  useEffect(() => {
    // Display only approved companies in dropdown
    const companiesArr: IDropdownOption[] = companies
      ?.filter((company: ISingleUser) => company.isApproved === true)
      ?.map((company: ISingleUser) => {
        return {
          key: company.companyName,
          value: company.companyName,
        };
      });
    companiesArr.unshift({
      key: "",
      value: "",
      default: "default",
    });
    setAllCompaniesNames(companiesArr);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(user)) {
      const subsidiariesArr: IDropdownOption[] = user?.subCompanies
        ?.filter(
          (subsidiary: ISingleSubsidiary) => subsidiary.isApproved === true
        )
        ?.map((subsidiary: ISingleSubsidiary) => {
          return {
            key: subsidiary.id,
            value: subsidiary.name,
          };
        });
      subsidiariesArr.unshift(
        ...[
          {
            key: "",
            value: "",
            default: "default",
          },
          {
            key: "parent",
            value: user?.companyName,
          },
        ]
      );
      setAllSubsidiariesNames(subsidiariesArr);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getAllCompaniesForBroker(setErrorHandlerObj, setIsLoading));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pageContent]);

  useEffect(() => {
    if (location.state) {
      // If it is routed from "Register certificate" button i.e to register a pending saved certificate
      const { certificate }: any = location.state;
      setPageContent("stage-five");
      setIsRoutedToRegisterCertificateOnly(true);
      const updatedCargoInput = {
        ...allCargosInputStates[0],
        premium: certificate.premium,
      };
      setSelectedCompanyFromBroker(certificate?.customer || null);
      // Update cargo premium, saved certificate id and policy type for the current certificate to be registered
      setPolicyType(certificate.policyType);
      setSavedCertificateId([certificate.id]);
      setAllCargosInputStates([updatedCargoInput]);
    } else {
      // If it isn't routed from the "Register certificate" button, i.e fresh certificate generation
      if (user) {
        if (user.isOpenCoverPaused) {
          setPageContent("user-paused");
        } else {
          // If customer type is BROKER
          if (user?.customerType === "Broker") {
            setPageContent("stage-zero-five");
            // setPageContent("stage-two");
            // setPolicyType("OpenCover");
            // setPolicyType("SingleTransit");
          } else {
            // If customer type is CUSTOMER
            setPageContent("stage-one");
          }
        }
      }
    }
  }, [user, location]);

  useEffect(() => {
    // Set Is from spreadsheet state to true if excel sheet becomes parsed into json
    setIsUploadedFromSpreadsheet(false);
    if (!isEmpty(cargoJSONArr)) setIsUploadedFromSpreadsheet(true);
  }, [cargoJSONArr]);

  useEffect(() => {
    // This piece re-initializes every data related to clauses back to default state when the policy type changes
    // As a change in policy type could alter the options for the clauses to be selected, hence, clauses have to be reselected
    if (policyType) {
      const updatedAllCargoInputData = allCargosInputStates?.map(
        (cargoObject) => {
          const updatedCargoObject = {
            ...cargoObject,
            clause: "",
            selectedPremiumPlan: "",
            selectedClauseRate: 0,
            rate: "",
          };
          return updatedCargoObject;
        }
      );
      setAllCargosInputStates(updatedAllCargoInputData);
    }
  }, [policyType]);

  return (
    <div
      className={`marine-policy-container ${
        isSumInsuredBreakdownModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top pageSummaryMessage="Generate your policy here" />
      <div className="marine-policy-container--inner">
        <Sidebar activeName={"Marine policy"} />

        {/* Gray Ovrlay */}
        <div
          onClick={() => {
            setIsSumInsuredBreakdownModalOpened(false);
          }}
          className={`gray-overlay ${
            isSumInsuredBreakdownModalOpened ? "" : "none"
          }`}
        ></div>

        {/* Investment Details Modal */}
        <SumInsuredBreakdownModal
          isSumInsuredBreakdownModalOpened={isSumInsuredBreakdownModalOpened}
          setIsSumInsuredBreakdownModalOpened={
            setIsSumInsuredBreakdownModalOpened
          }
          allCargosInputStates={allCargosInputStates}
          currentActiveCargoIndex={currentActiveCargoIndex}
        />

        {/* Error Handler Obj Wrapper */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app-error-success-message-wrapper"
        />

        <section className="marine-policy-container-main-section">
          {pageContent === "user-paused" ? (
            <UserPaused />
          ) : pageContent === "stage-zero-five" ? (
            <PolicyStageZeroFive
              setPolicyType={setPolicyType}
              pageContent={pageContent}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "policy-json-process" ? (
            <CargosJsonProcess
              cargoJSONArr={cargoJSONArr}
              setAllCargosInputStates={setAllCargosInputStates}
              setPageContent={setPageContent}
            />
          ) : pageContent === "stage-one" ? (
            <PolicyStageOne
              companies={companies}
              companyName={companyName}
              selectedCompanyFromBroker={selectedCompanyFromBroker}
              setCompanyName={setCompanyName}
              subsidiaryName={subsidiaryName}
              setSubsidiaryName={setSubsidiaryName}
              setSelectedCompanyFromBroker={setSelectedCompanyFromBroker}
              setBrokerCompanyId={setBrokerCompanyId}
              allCargosInputStates={allCargosInputStates}
              policyType={policyType}
              setAllCargosInputStates={setAllCargosInputStates}
              setPolicyType={setPolicyType}
              currentActiveCargoIndex={currentActiveCargoIndex}
              allCompaniesNames={allCompaniesNames}
              allSubsidiariesNames={allSubsidiariesNames}
              setCargoJSONArr={setCargoJSONArr}
              pageContent={pageContent}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-two" ? (
            <PolicyStageTwo
              allCargosInputStates={allCargosInputStates}
              setAllCargosInputStates={setAllCargosInputStates}
              currentActiveCargoIndex={currentActiveCargoIndex}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-two-five" ? (
            <PolicyStageTwoFive
              allCargosInputStates={allCargosInputStates}
              setAllCargosInputStates={setAllCargosInputStates}
              currentActiveCargoIndex={currentActiveCargoIndex}
              pageContent={pageContent}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-three" ? (
            <PolicyStageThree
              allCargosInputStates={allCargosInputStates}
              setAllCargosInputStates={setAllCargosInputStates}
              currentActiveCargoIndex={currentActiveCargoIndex}
              setIsSumInsuredBreakdownModalOpened={
                setIsSumInsuredBreakdownModalOpened
              }
              pageContent={pageContent}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-four" ? (
            <PolicyStageFour
              companies={companies}
              companyName={companyName}
              brokerCompanyId={brokerCompanyId}
              subsidiaryName={subsidiaryName}
              allCargosInputStates={allCargosInputStates}
              policyType={policyType}
              isUploadedFromSpreadsheet={isUploadedFromSpreadsheet}
              allCompaniesNames={allCompaniesNames}
              allSubsidiariesNames={allSubsidiariesNames}
              selectedCompanyFromBroker={selectedCompanyFromBroker}
              setAllCargosInputStates={setAllCargosInputStates}
              setCompanyName={setCompanyName}
              setSubsidiaryName={setSubsidiaryName}
              setBrokerCompanyId={setBrokerCompanyId}
              setSelectedCompanyFromBroker={setSelectedCompanyFromBroker}
              setCurrentActiveCargoIndex={setCurrentActiveCargoIndex}
              setSavedCertificateId={setSavedCertificateId}
              pageContent={pageContent}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-five" ? (
            <PolicyStageFive
              allCargosInputStates={allCargosInputStates}
              stageFourInputStates={stageFourInputStates}
              savedCertificateId={savedCertificateId}
              selectedCompanyFromBroker={selectedCompanyFromBroker}
              setStageFourInputStates={setStageFourInputStates}
              policyType={policyType}
              pageContent={pageContent}
              isRoutedToRegisterCertificateOnly={
                isRoutedToRegisterCertificateOnly
              }
              setPageContent={setPageContent}
              setSubmittedCertificatesData={setSubmittedCertificatesData}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "stage-six" ? (
            <PolicyStageSix
              allCargosInputStates={allCargosInputStates}
              stageFourInputStates={stageFourInputStates}
              savedCertificateId={savedCertificateId}
              selectedCompanyFromBroker={selectedCompanyFromBroker}
              setSubmittedCertificatesData={setSubmittedCertificatesData}
              policyType={policyType}
              setStageFiveInputStates={setStageFiveInputStates}
              setPageContent={setPageContent}
              setErrorHandlerObj={setErrorHandlerObj}
            />
          ) : pageContent === "process-successful" ? (
            <PolicyProcessSuccessful
              submittedCertificatesData={submittedCertificatesData}
            />
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
}

export default MarinePolicy;
